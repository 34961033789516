<template>
  <div class="bg-white text-gray-600 work-sans leading-normal text-base tracking-normal">
    <section class="bg-white text-left lg:flex">
      <div class="container py-8 px-6 mx-auto ml-0 md:ml-4">
        <span class="uppercase tracking-wide no-underline hover:no-underline font-bold text-gray-800 text-xl mb-8">
          {{ jsonMisc.aboutUsContent.heading }}
        </span>
        <div v-for="(obj, index) in jsonMisc.aboutUsContent.para" :key="index" class="mb-8">
          <p class="my-4 text-gray-700 font-semibold">{{ obj.subHeading }}</p>
          <p class="mb">{{ obj.content }}</p>
        </div>
        <p class="my-4 text-gray-700 font-bold">Company Profile</p>
        <table class="text-black w-full">
          <tbody>
            <tr v-for="(obj, index) in jsonMisc.aboutUsContent.companyProfileTable" :key="index" class="w-full">
                <th data-label="Types" class="border border-gray-500 text-gray-700 py-2 px-4">{{ obj.head }}</th>
                <td data-label="Types" class="border border-gray-500 py-2 px-4">{{ obj.body }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- component -->
      <!-- <div class="md:flex lg:flex-col mr-0 md:mr-4">
        <div class="flex flex-col max-w-xs text-white bg-orange-1100 p-6 mx-6 my-t md:mt-12 h-64 rounded-lg relative">
          <div class="">
            <h3 class="text-xl font-bold pb-2">Contact Us</h3>
            <p class="text-sm">For More Information please call : +(91) 9810015044 / 9999637374</p>
          </div>
          <div class="mt-auto pb-6 flex justify-between">
            <span class="text-sm">Contact Us</span>
            <svg class="w-6 h-6" fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd"></path>
            </svg>
          </div>
        </div>
        <div class="m-6 h-auto rounded-lg relative">
          <span class="text-md font-bold">Company Location</span>
          <div class="border border-gray-700 module-content clearfix rounded-lg mt-2">
            <iframe :src="jsonMisc.googleMap.googleMapUrl" width="100%" height="270px" frameborder="0" class="rounded-lg" allowfullscreen=""></iframe>
          </div>
        </div>
      </div> -->
    </section>
  </div>
</template>

<script>
import {
  // SearchIcon,
} from "vue-feather-icons";
export default {
  name: "aboutus",
  components: {
    // SearchIcon,
  },
  data() {
    return {
      productsInCart: [],
      isEnquired: false,
    };
  },
  props: {
    isCheckout: Boolean,
    jsonMisc: Object,
  },
  watch: {
    isCheckout(){
      // console.log('this.isCheckout', this.isCheckout)
      return this.isCheckout;
    },
  },
  mounted() {

  },
  methods: {

  },
};
</script>
